<template>
  <div>
    <h1 class="h3">{{ video.title }}</h1>
    <div class="row" v-if="is_loaded">
      <div class="col-md-9">
        <div class="vimeo-player">
          <youtube :video-id="$youtube.getIdFromUrl(video.video_url)" ref="youtube" :player-vars="{autoplay: 1, color: '#f6b923', modestbranding: 1, rel: 0}" />
        </div>
        
        <div class="card mt-2">
          <div class="px-3 py-0">
            <video-comments :about="video.title" type="conference" :id="$route.params.id" role="admin" v-if="is_loaded" />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="px-2 py-2">
            <table class="table table-responsive table-borderless table-condensed">
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>{{ $localizer.parse(video.date) }}</td>
                </tr>
                <tr>
                  <td>Comments</td>
                  <td>{{ video.comments }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <a href="#" class="btn btn-secondary btn-sm btn-block" @click.prevent="deleteVideo">Delete Video</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      new_comment: {},
      new_reply: {},
      video: {},
      is_loaded: false
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.is_loaded = false;

      this.$axios
        .get(`/api/v1/moderator/conference/${this.$route.params.id}`)
        .then((response) => {
          this.video = response.data.video;
          this.$loader.stop();
          this.is_loaded = true;
        });
    },

    deleteVideo() {
      this.$loader.start()

      this.$axios.delete(`/api/v1/moderator/conference/${this.$route.params.id}`).then(() => {
        this.$loader.stop()
        this.$router.push({ name: 'moderator.conference.index' })
      })
    }
  },
};
</script>
